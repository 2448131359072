div.ant-picker-month-panel, div.ant-picker-date-panel {
    width: 185px;
    font-size: x-small;
}

.ant-picker-content {
    width: 165px !important;
}

.ant-picker-content th {
    width: 20px !important;
}

.ant-picker input {
    color: #a8a8a8;
}