.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Dialog Modal Unset */
.modal-dialog-margin-unset {
    margin: unset !important;
}

.modal-body-padding-unset {
    padding: unset !important;
}

.comment-profile_img {
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
}

.scroll-width-unset {
    width: unset !important;
}